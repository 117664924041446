import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { CategoryTemplate } from 'views/Admin/OrgSettings/components';
import { Uploader } from 'components';
import { CategoryInfo } from '../';
import RevisionList from './RevisionList';
import {
  createOrUpdateDocumentCategory,
  deleteDocumentCategory,
  documentFetch,
  duplicateCheckReset,
  duplicateFilesCheck,
  fetchProjectCategories,
  objectCreateOrUpdate,
  objectStatusFetch,
  orgCategoryTemplatesFetch,
  projectUpdate,
  revisionTypesFetch,
  userRolesFetch
} from '../../../../redux';
import { documentCategorySchema } from '../../validations/schema';
import { validateDocumentCategory } from 'views/Object/validations/validator';
import {
  formatCategories,
  getFilenameWithoutID,
  getFilenameWithoutIDEncoded,
  hasError,
  isObjectEmpty,
  nullifyCategoryIds,
  valueInArray
} from 'common/helper';
import {
  getCategoryInfo,
  getExistingCategoryNames,
  getNextObjectRevision,
  getRevisionInfo,
  getRevisionSequenceDisplay,
  getUploadPath,
  isOrgInDistribution
} from '../helper';
import { useStyles } from '../../styles';
import classNames from 'classnames';
import validate from 'validate.js';
import _ from 'lodash';
import {
  ACCESS_CONTROL_LEVEL,
  ACCESS_CONTROL_ORG,
  ADD,
  ALL,
  ANCESTRY,
  CATEGORIES,
  CATEGORY,
  DEFAULT_REVISION_TYPE,
  DISTRIBUTION,
  DOCUMENTATION_TYPE_MULTIPLE,
  EDIT,
  LS_ORGANIZATION_ID,
  NAME,
  OBJECT,
  OBJECT_DOC,
  ORGANIZATION_ID,
  REVISION,
  ROLE_OBSERVER,
  SELECTED_CATEGORY,
  STATUS_ISSUED_FOR_APPROVAL,
  STATUS_PUBLISHED,
  TEMPLATE,
  TYPES
} from 'common/constants';

const DocUploader = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    document: {
      categories: documentCategories,
      category,
      categoryDeleted,
      categoryError,
      categoryTemplates,
      types: documentTypes
    },
    object: {
      duplicateError,
      duplicateFiles,
      duplicateLoading,
      error,
      revisionTypes = [],
      status: statusList,
      types: objectTypes,
      updated
    },
    project: {
      error: projectError,
      projectSelected: { organizations: projectOrganizations = [] },
      updated: projectUpdated
    },
    user: { roles }
  } = useSelector(state => state);
  const {
    dropZoneProps,
    isMultipleUpload,
    isRevision,
    mergeDistribution,
    object: currObject,
    onPopupClose,
    projectId,
    reloadObjList,
    uploadPath
  } = props;
  const organizationId = localStorage.getItem(LS_ORGANIZATION_ID);
  const [formState, setFormState] = useState({
    errors: validate({}, documentCategorySchema),
    values: {
      metadata: { created_by_org_id: organizationId },
      project_id: projectId
    },
    categories: [],
    existingCategoryNames: [],
    addTemplate: false,
    hasAccessControl: false,
    hasApprover: false,
    hasAttachmentError: false,
    hasCategories: true,
    hasCustomRevision: false,
    hasRevisionError: false,
    isUploading: false,
    showRevisionList: false,
    submitted: false,
    submitStatus: null,
    upsertAction: null,
    upsertCategory: false
  });
  const {
    addTemplate,
    categories,
    errors,
    hasAccessControl,
    hasApprover,
    hasAttachmentError,
    hasCategories,
    hasCustomRevision,
    hasRevisionError,
    isUploading,
    showRevisionList,
    submitStatus,
    submitted,
    upsertAction,
    upsertCategory,
    values: fsValues
  } = formState;

  const {
    attachments = [],
    distribution: objDist,
    document_categories = [],
    metadata: {
      access_control_level,
      access_control_org,
      revision_sequence_display,
      selected_category
    },
    name: objName,
    object_type_id,
    organization,
    organization_id,
    prev_categories = [],
    selected_template
  } = fsValues;
  const distribution = objDist?.internal.concat(objDist.guests) || [];
  const organizations = projectOrganizations.reduce(
    (acc, org) => {
      if (org.id === organizationId) acc.creator = org;
      else acc.others.push(org);
      return acc;
    },
    { creator: {}, others: [] }
  );
  let distributionList = [...organizations.others];
  if (
    distributionList.length > 0 &&
    distributionList.length !== distribution.length
  )
    distributionList.unshift({
      id: ALL,
      name: intl.formatMessage({
        defaultMessage: 'TO ALL',
        id: 'object.TO_ALL'
      })
    });
  // The organization should be able to remove restriction for own users
  // without falling back to the default set by the Doc creator.
  // However, if access_level is 0 (Observer), it should not display anything in the form
  // since Observer is not one of the options -- set role to null
  const role_access_level =
    access_control_org && organizationId in access_control_org
      ? access_control_org[organizationId]
      : access_control_level;
  const role =
    roles && role_access_level
      ? roles.find(r => r.access_level === role_access_level)
      : null;

  useEffect(() => {
    if (isRevision && currObject) {
      const revision = getNextObjectRevision(currObject.metadata.revision);
      const revision_sequence_display = getRevisionSequenceDisplay(
        currObject.metadata.selected_category?.revision_type?.action_name,
        revision
      );
      setFormState(formState => ({
        ...formState,
        hasAccessControl: currObject.metadata.access_control_level != null,
        hasApprover: !!currObject.organization,
        values: {
          ...currObject,
          id: null,
          attachments: [],
          discipline_type_id: null,
          distribution: currObject.distribution,
          metadata: {
            ...currObject.metadata,
            revision,
            revision_sequence_display,
            prev_version_object_id: currObject.id,
            revised_by_org_id: organizationId
          },
          object_type_id: currObject.object_type.id,
          organization: currObject.organization,
          organization_id: currObject.organization?.id || null,
          project_id: currObject.values?.project_id || projectId,
          status_id: currObject.status.id
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currObject, isRevision]);

  useEffect(() => {
    if (updated && submitted) onPopupClose();
  }, [submitted, onPopupClose, updated, dispatch]);

  useEffect(() => {
    if (documentTypes.length === 0) dispatch(documentFetch(TYPES));
    else
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          metadata: {
            ...formState.values.metadata,
            documentation_type: documentTypes.find(
              dt => dt.name === DOCUMENTATION_TYPE_MULTIPLE
            )
          }
        }
      }));
  }, [documentTypes, dispatch]);

  useEffect(() => {
    if (organizationId) dispatch(orgCategoryTemplatesFetch(organizationId));
    if (projectId) dispatch(fetchProjectCategories(CATEGORIES, projectId));
    if (roles.length === 0) dispatch(userRolesFetch());
    if (revisionTypes.length === 0) dispatch(revisionTypesFetch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (object_type_id)
      dispatch(
        objectStatusFetch({ type: OBJECT, params: `type_id=${object_type_id}` })
      );
  }, [object_type_id, dispatch]);

  useEffect(() => {
    if (documentCategories)
      setFormState(fs => {
        return {
          ...fs,
          categories: formatCategories(documentCategories),
          hasCategories: documentCategories.length > 0
        };
      });
  }, [documentCategories]);

  useEffect(() => {
    if (category?.id) {
      if (upsertAction === EDIT) reloadObjList();

      dispatch(fetchProjectCategories(CATEGORIES, projectId));
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          metadata: {
            ...formState.values.metadata,
            selected_category: {
              ...formState.values.metadata.selected_category,
              ...category
            }
          }
        },
        upsertAction: null,
        upsertCategory: false
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, projectId, dispatch]);

  useEffect(() => {
    if (categoryDeleted) {
      dispatch(fetchProjectCategories(CATEGORIES, projectId));
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          metadata: { ...formState.values.metadata, selected_category: null }
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDeleted]);

  useEffect(() => {
    if (projectUpdated && !projectError) {
      dispatch(fetchProjectCategories(CATEGORIES, projectId));
      handleSetTemplate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUpdated, projectError, dispatch]);

  useEffect(() => {
    if (!duplicateLoading && duplicateFiles) {
      if (duplicateFiles.length > 0) {
        const duplicates = duplicateFiles.map(df => ({
          file: getFilenameWithoutID(df.file),
          id: df.object_id,
          name: df.object_name,
          title: df.object_title,
          revision: df.object_revision,
          description: df.object_description,
          created_by_id: df.object_created_by_id,
          created_by_org_id: df.object_created_by_org_id
        }));
        setFormState(formState => ({
          ...formState,
          showRevisionList: true,
          values: {
            ...formState.values,
            attachments: processFiles(duplicates, formState.values.attachments)
          }
        }));
      } else verifiedSubmit(formState.submitStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateLoading]);

  const processFiles = (existingAttachments, files = []) => {
    return files.reduce((acc, v) => {
      let att = {
        file: v.file,
        rank: 1,
        uploaded_by_org_id: organizationId
      };
      const dupFiles = existingAttachments.filter(
        pa =>
          pa.file.toLowerCase() === getFilenameWithoutID(v.file).toLowerCase()
      );
      if (dupFiles.length > 0) {
        const {
          id,
          created_by_id,
          created_by_org_id,
          description,
          name,
          revision,
          title
        } = dupFiles[0];
        att = {
          ...att,
          isRevision: true,
          duplicates: dupFiles,
          name,
          title,
          revisionObj: dupFiles[0],
          metadata: {
            created_by_id,
            created_by_org_id,
            description,
            prev_version_object_id: id,
            revised_by_org_id: organizationId,
            revision: getNextObjectRevision(revision)
          }
        };
      }
      return acc.concat(att);
    }, []);
  };

  const addAttachment = newData => {
    const newFiles = Object.values(newData.files);
    let newAttachments;
    if (isRevision)
      newAttachments =
        newFiles.length > 0
          ? [
              {
                file: newFiles[0].file,
                rank: 1,
                uploaded_by_org_id: organizationId
              }
            ]
          : [];
    else newAttachments = processFiles([], newFiles);

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        attachments: newAttachments
      },
      hasAttachmentError: newData.hasError,
      isUploading: newData.isUploading
    }));
  };

  const createOrUpdateCategory = () => {
    const selected_category_path = getCategoryInfo(
      categories,
      selected_category
    );

    dispatch(
      createOrUpdateDocumentCategory(CATEGORIES, CATEGORY, {
        ...selected_category,
        selected_category_path,
        project_id: projectId
      })
    );
  };

  const deleteCategory = category_id => {
    const del = window.confirm(
      intl.formatMessage(
        { id: 'object.DELETE_ITEM_CONFIRMATION' },
        { name: intl.formatMessage({ id: 'common.CATEGORY' }) }
      ) +
        '\n' +
        intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' })
    );
    if (del) dispatch(deleteDocumentCategory(CATEGORIES, category_id));
  };

  const handleCancel = event => {
    handleCategoryChange(
      event,
      upsertAction === ADD ? selected_category?.ancestry : selected_category
    );
  };

  const handleCategoryChange = (event, value) => {
    event.persist();
    let upsertCategory = false,
      objectType = '',
      newValue,
      revision = 0;

    if (value?.name) {
      newValue = value;
      objectType = objectTypes.find(type => type.ref_name === OBJECT_DOC);

      if (value.inputValue) {
        const revision_type = revisionTypes.find(
          type => type.action_name === DEFAULT_REVISION_TYPE
        );
        upsertCategory = true;
        newValue = {
          ...newValue,
          name: value.inputValue,
          revision_type: revision_type || null,
          revision_type_id: revision_type.id
        };
      }
    }

    setFormState(formState => {
      const metadata = {
        ...formState.values.metadata,
        [SELECTED_CATEGORY]: newValue,
        revision: revision
      };
      return {
        ...formState,
        values: {
          ...formState.values,
          metadata: metadata,
          object_type_id: objectType?.id || ''
        },
        errors: { ...validate(metadata, documentCategorySchema) },
        upsertAction: upsertCategory ? formState.upsertAction : null,
        upsertCategory
      };
    });
  };

  const handleUpsertCategory = (event, category, action) => {
    event.persist();
    event.stopPropagation();

    setFormState(formState => {
      const selected_category =
        category || formState.values.metadata.selected_category;
      const defaultRevisionType = revisionTypes.find(
        type => type.action_name === DEFAULT_REVISION_TYPE
      );

      const metadata = {
        ...formState.values.metadata,
        revision: 0,
        selected_category:
          action === ADD
            ? {
                ancestry: selected_category,
                ancestry_id: selected_category.id,
                revision_type: defaultRevisionType,
                revision_type_id: defaultRevisionType?.id
              }
            : selected_category
      };

      const objectType = objectTypes.find(type => type.ref_name === OBJECT_DOC);

      const existingNames = getExistingCategoryNames(
        metadata.selected_category?.ancestry,
        documentCategories
      );

      return {
        ...formState,
        values: {
          ...formState.values,
          metadata: metadata,
          object_type_id: objectType?.id || ''
        },
        errors: validateDocumentCategory(metadata.selected_category),
        upsertAction: action,
        upsertCategory: true,
        existingCategoryNames: existingNames,
        original_category: action === EDIT ? category : null
      };
    });
  };

  const handleNewCategory = (event, value, field) => {
    if (event) event.persist();
    setFormState(formState => {
      let metadata = { ...formState.values.metadata };
      let existingNames = formState.existingCategoryNames;

      switch (field) {
        case ANCESTRY:
          metadata.selected_category = {
            ...metadata.selected_category,
            ancestry: value,
            ancestry_id: value?.id || null
          };
          existingNames = getExistingCategoryNames(value, documentCategories);
          break;
        case NAME:
          metadata.selected_category = {
            ...metadata.selected_category,
            name: value
          };
          break;
        case REVISION: {
          metadata.revision = 0;
          metadata.selected_category = {
            ...metadata.selected_category,
            revision_type: value,
            revision_type_id: value?.id || null
          };
          break;
        }
        default:
          break;
      }

      if (
        formState.original_category &&
        formState.original_category.ancestry?.id ===
          metadata.selected_category?.ancestry?.id
      ) {
        existingNames = existingNames.filter(
          e => e !== formState.original_category.name
        );
      }

      return {
        ...formState,
        values: { ...formState.values, metadata: metadata },
        errors: validateDocumentCategory(
          metadata.selected_category,
          existingNames
        ),
        existingCategoryNames: existingNames
      };
    });
  };

  const handleDataChange = (event, value, field) => {
    event.persist();
    let updatedData = {};
    let hasRevisionError = false;

    // https://eslint.org/docs/latest/rules/no-case-declarations
    switch (field) {
      case ACCESS_CONTROL_LEVEL: {
        let access_value = value?.access_level || null;
        if (fsValues.metadata.created_by_org_id !== organizationId) {
          field = ACCESS_CONTROL_ORG;
          access_value = {
            ...fsValues.metadata.access_control_org,
            [organizationId]: access_value ?? 0
          };
        }
        updatedData = {
          metadata: {
            ...fsValues.metadata,
            [field]: access_value
          }
        };
        break;
      }
      case ORGANIZATION_ID: {
        let updatedDistribution = objDist
          ? { ...objDist }
          : { internal: [], guests: [] };
        if (
          value &&
          !updatedDistribution.internal.find(
            d => d.organization.id === value.id
          )
        )
          updatedDistribution.internal.push({
            organization: { id: value.id, name: value.name }
          });

        updatedData = {
          [field]: value?.id || null,
          distribution: updatedDistribution,
          organization: value
        };
        break;
      }
      case DISTRIBUTION: {
        let dataSource;
        if (value.find(v => v.id === ALL))
          dataSource =
            isRevision && fsValues.metadata.created_by_org_id !== organizationId
              ? projectOrganizations
              : organizations.others;
        else {
          let list = [];
          const origDistro = currObject?.distribution?.internal;
          if (
            currObject &&
            isOrgInDistribution(
              origDistro,
              currObject.metadata.created_by_org_id
            )
          )
            list.push(currObject.metadata.created_by_org_id); // creator
          if (isRevision && isOrgInDistribution(origDistro, organizationId))
            list.push(organizationId); // reviser
          if (organization?.id) list.push(organization.id); // approver

          const appendOrgs = _.uniq(list).reduce((acc, orgId) => {
            if (
              orgId &&
              !value.find(v => valueInArray([v.id, v.organization?.id], orgId))
            )
              acc = acc.concat(
                projectOrganizations.find(org => org.id === orgId)
              );
            return acc;
          }, []);
          dataSource = value.concat(appendOrgs);
        }
        const newValue = dataSource.map(org =>
          org.organization
            ? org
            : { organization: { id: org.id, name: org.name } }
        );
        updatedData = { [field]: { internal: newValue, guests: [] } };
        break;
      }
      case TEMPLATE: {
        const dc =
          value?.document_categories?.map(category =>
            nullifyCategoryIds(category)
          ) || [];
        updatedData = {
          selected_template: { name: value?.name || '' },
          document_categories: _.cloneDeep(dc),
          prev_categories: _.cloneDeep(dc)
        };
        break;
      }
      case REVISION: {
        const { revision, revision_sequence_display } = getRevisionInfo(
          fsValues.metadata?.selected_category?.revision_type?.action_name,
          value,
          getNextObjectRevision(currObject?.metadata?.revision),
          currObject?.metadata?.revision
        );
        hasRevisionError = revision == null;
        updatedData = {
          metadata: {
            ...fsValues.metadata,
            [field]: revision,
            revision_sequence_display: revision_sequence_display || value
          }
        };
        break;
      }
      default:
        break;
    }

    setFormState(formState => ({
      ...formState,
      hasRevisionError,
      values: {
        ...formState.values,
        ...updatedData
      }
    }));
  };

  const handleSetTemplate = value => {
    setFormState(fs => ({
      ...fs,
      addTemplate: value,
      values: { ...fs.values, selected_template: {} }
    }));
  };

  const handleCheckbox = type => {
    setFormState(fs => {
      let otherValues = {};
      let hasRevisionError = fs.hasRevisionError;
      if (type === 'hasApprover')
        otherValues = {
          organization_id: null,
          organization: null
        };
      else if (type === 'hasAccessControl') {
        let metadata = { ...fs.values.metadata };
        if (fs.values.metadata.created_by_org_id === organizationId)
          delete metadata.access_control_level;
        else {
          const aco = metadata.access_control_org;
          if (aco) delete aco[organizationId];
          metadata = { ...metadata, access_control_org: aco };
        }

        otherValues = {
          metadata: metadata
        };
      } else if (type === 'hasCustomRevision') {
        if (fs[type]) {
          // checkbox is unchecked, revert to original value
          const revision =
            isRevision && currObject
              ? getNextObjectRevision(currObject.metadata.revision)
              : 0;
          const revision_sequence_display = getRevisionSequenceDisplay(
            currObject?.metadata?.selected_category?.revision_type?.action_name,
            revision
          );
          otherValues = {
            metadata: {
              ...fs.values.metadata,
              revision,
              revision_sequence_display
            }
          };
          hasRevisionError = false;
        }
      }

      return {
        ...fs,
        [type]: !fs[type],
        hasRevisionError,
        values: {
          ...fs.values,
          ...otherValues
        }
      };
    });
  };

  const updateCategories = (event, _index, _field, value, extra = {}) => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        document_categories: value,
        prev_categories: extra.updatePrev
          ? _.cloneDeep(value)
          : formState.values.prev_categories
      }
    }));
  };

  const copyTemplateToProject = event => {
    event.persist();
    dispatch(
      projectUpdate(projectId, { document_categories: document_categories })
    );
  };

  const verifiedSubmit = status => {
    const objStatus = statusList.find(s => s.name === status);
    const categoryPath = getCategoryInfo(categories, selected_category);
    const name = isRevision
      ? objName
      : OBJECT_DOC + `-${organizations.creator.org_meta.company_code}`;
    delete fsValues.prev_categories;
    const params = {
      ...fsValues,
      name: name,
      status: objStatus,
      status_id: objStatus?.id,
      metadata: {
        ...fsValues.metadata,
        // only pass the necessary info for selected_category
        selected_category: {
          id: selected_category.id,
          name: selected_category.name,
          ref_name: selected_category.ref_name,
          project_id: selected_category.project_id || projectId,
          ancestry_id: selected_category.ancestry_id,
          revision_type: selected_category.revision_type,
          revision_type_id: selected_category.revision_type_id
        },
        selected_category_path: categoryPath
      },
      new_comment: fsValues.new_comment_text ? fsValues.new_comment : null,
      organization_id: status === STATUS_PUBLISHED ? null : organization_id,
      mergeDistribution,
      isMultipleUpload
    };
    setFormState(formState => ({
      ...formState,
      submitted: true
    }));
    dispatch(objectCreateOrUpdate(params));
    dispatch(duplicateCheckReset(false));
  };

  const handleSubmit = (event, status) => {
    event.persist();
    let submit = true;
    if (distribution.length === 0 && !showRevisionList)
      submit = window.confirm(
        intl.formatMessage({ id: 'object.EMPTY_DISTRIBUTION_MESSAGE' })
      );

    if (submit) {
      if (showRevisionList || isRevision)
        verifiedSubmit(formState.submitStatus || status);
      else {
        dispatch(
          duplicateFilesCheck({
            project_id: projectId,
            selected_category_id: selected_category.id,
            files: attachments.map(a => getFilenameWithoutIDEncoded(a.file))
          })
        );
        setFormState(formState => ({ ...formState, submitStatus: status }));
      }
    }
  };

  const disableBtns =
    isObjectEmpty(selected_category) ||
    attachments.length === 0 ||
    hasAttachmentError ||
    hasRevisionError ||
    isUploading ||
    upsertCategory ||
    (submitted && !error);
  return (
    <div className={classes.componentPopupRoot}>
      {error || categoryError || projectError || duplicateError ? (
        <Alert className={classes.marginBottom1} severity="error">
          {error || categoryError || projectError || duplicateError}
        </Alert>
      ) : null}
      <Grid alignItems="center" container spacing={2}>
        {hasCategories ? null : (
          <>
            <Grid
              className={addTemplate ? null : classes.noBottomPadding}
              item
              md={12}
              xs={12}>
              <Typography className={classes.textAlignJustify}>
                <FormattedMessage
                  defaultMessage={
                    "Looks like you don't have any custom categories set." +
                    ' Do you want to use a template to set this up?'
                  }
                  id="object.UPLOADER_TEMPLATE_MESSAGE"
                />
              </Typography>
            </Grid>
            {addTemplate ? null : (
              <Grid className={classes.noTopPadding} item md={12} xs={12}>
                <Button
                  className={classes.floatRight}
                  color="primary"
                  onClick={() => handleSetTemplate(!addTemplate)}
                  size="small"
                  variant="contained">
                  <FormattedMessage
                    defaultMessage="Add category template"
                    id="object.ADD_CATEGORY_TEMPLATE"
                  />
                </Button>
              </Grid>
            )}
          </>
        )}
        {isRevision || showRevisionList || addTemplate ? null : (
          <>
            {upsertCategory ? (
              <>
                <CategoryInfo
                  categories={documentCategories}
                  errors={errors}
                  fnCancel={handleCancel}
                  fnChange={handleNewCategory}
                  fnSave={createOrUpdateCategory}
                  selected_category={selected_category}
                />
                <Grid item md={12} xs={12}>
                  <Divider />
                </Grid>
              </>
            ) : (
              <Grid item md={12} xs={12}>
                <Autocomplete
                  autoHighlight
                  classes={{
                    option: classes.autocompleteOption
                  }}
                  getOptionLabel={option => {
                    return option.name
                      ? intl.formatMessage({
                          defaultMessage: option.name,
                          id: 'object.' + option.name.toUpperCase()
                        })
                      : '';
                  }}
                  id={SELECTED_CATEGORY}
                  name={SELECTED_CATEGORY}
                  onChange={handleCategoryChange}
                  options={[{ name: '', id: null }, ...categories]}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={hasError(errors, SELECTED_CATEGORY)}
                      fullWidth
                      helperText={
                        hasError(errors, SELECTED_CATEGORY)
                          ? errors.selected_category[0]
                          : null
                      }
                      label={
                        <FormattedMessage
                          defaultMessage="Select category"
                          id="object.SELECT_CATEGORY"
                        />
                      }
                      required
                      size="small"
                      variant="outlined"
                    />
                  )}
                  renderOption={option => (
                    <>
                      <Typography
                        style={{
                          marginLeft: option.indent_count
                        }}>
                        {option.name
                          ? intl.formatMessage({
                              defaultMessage: option.name,
                              id: 'object.' + option.name.toUpperCase()
                            })
                          : ''}
                      </Typography>
                      {option.inputValue ? null : (
                        <div>
                          <Button
                            className={option.id ? classes.hidden : null}
                            onClick={e => handleUpsertCategory(e, option, ADD)}
                            size="small">
                            <Typography variant="overline">
                              <FormattedMessage
                                defaultMessage="Add category"
                                id="object.ADD_CATEGORY"
                              />
                            </Typography>
                          </Button>
                          {option.id ? (
                            <>
                              <IconButton
                                aria-label="edit"
                                className={classes.hidden}
                                onClick={e =>
                                  handleUpsertCategory(e, option, EDIT)
                                }
                                size="small">
                                <EditIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                aria-label="edit"
                                className={classNames(
                                  classes.deleteIcon,
                                  classes.hidden
                                )}
                                onClick={() => deleteCategory(option.id)}
                                size="small">
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : null}
                        </div>
                      )}
                    </>
                  )}
                  size="small"
                  value={selected_category || ''}
                />
              </Grid>
            )}
          </>
        )}
        {showRevisionList || addTemplate ? null : (
          <>
            <Grid item md={12} xs={12}>
              <Autocomplete
                filterSelectedOptions
                getOptionLabel={option =>
                  option.name || option.organization?.name || ''
                }
                getOptionSelected={(distributionList, distribution) => {
                  return (
                    distributionList.id ===
                    (distribution.organization?.id || distribution.id)
                  );
                }}
                id="uploader_distribution"
                multiple
                name={DISTRIBUTION}
                onChange={(event, value) =>
                  handleDataChange(event, value, DISTRIBUTION)
                }
                options={distributionList}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    label={
                      <FormattedMessage
                        defaultMessage="Share with"
                        id="object.SHARE_WITH"
                      />
                    }
                    size="small"
                    variant="outlined"
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    const indelible = valueInArray(
                      [
                        currObject?.metadata?.created_by_org_id, // creator
                        organizationId, // reviser
                        organization?.id // approver
                      ],
                      option.organization.id
                    );
                    const tagProps = getTagProps({ index });
                    return (
                      <Chip
                        {...tagProps}
                        deleteIcon={indelible ? <></> : null}
                        label={option.organization.name}
                        onDelete={tagProps.onDelete}
                        size="small"
                      />
                    );
                  })
                }
                size="small"
                value={distribution}
              />
            </Grid>
            {isRevision ? (
              <Grid alignItems="center" container item>
                <Grid item md={5} xs={12}>
                  <FormControlLabel
                    className={classes.marginLeft1}
                    control={
                      <Checkbox
                        checked={hasCustomRevision}
                        className={classes.disableHoverEffect}
                        color="primary"
                        onChange={() => handleCheckbox('hasCustomRevision')}
                      />
                    }
                    label={
                      <FormattedMessage
                        defaultMessage="Manually set revision"
                        id="object.MANUALLY_SET_REVISION"
                      />
                    }
                    labelPlacement="start"
                  />
                </Grid>
                {hasCustomRevision ? (
                  <Grid item md={7} xs={12}>
                    <TextField
                      error={hasRevisionError}
                      fullWidth
                      helperText={
                        hasRevisionError
                          ? intl.formatMessage({ id: 'error.INVALID_REVISION' })
                          : null
                      }
                      onChange={e =>
                        handleDataChange(e, e.target.value, REVISION)
                      }
                      size="small"
                      value={revision_sequence_display || ''}
                      variant="outlined"
                    />
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
            <Grid alignItems="center" container item>
              <Grid item md={5} xs={12}>
                <FormControlLabel
                  className={classes.marginLeft1}
                  control={
                    <Checkbox
                      checked={hasApprover}
                      className={classes.disableHoverEffect}
                      color="primary"
                      onChange={() => handleCheckbox('hasApprover')}
                    />
                  }
                  label={
                    <FormattedMessage
                      defaultMessage="Approval needed"
                      id="object.SELECT_APPROVER_CONFIRMATION"
                    />
                  }
                  labelPlacement="start"
                />
              </Grid>
              {hasApprover ? (
                <Grid item md={7} xs={12}>
                  <Autocomplete
                    filterSelectedOptions
                    getOptionLabel={option => option.name}
                    id={ORGANIZATION_ID}
                    name={ORGANIZATION_ID}
                    onChange={(event, value) =>
                      handleDataChange(event, value, ORGANIZATION_ID)
                    }
                    options={organizations.others}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        label={
                          <FormattedMessage
                            defaultMessage="Approver"
                            id="object.APPROVER"
                          />
                        }
                        size="small"
                        variant="outlined"
                      />
                    )}
                    size="small"
                    value={organization || null}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid alignItems="center" container item>
              <Grid item md={5} xs={12}>
                <FormControlLabel
                  className={classes.marginLeft1}
                  control={
                    <Checkbox
                      checked={hasAccessControl}
                      className={classes.disableHoverEffect}
                      color="primary"
                      onChange={() => handleCheckbox('hasAccessControl')}
                    />
                  }
                  label={
                    <FormattedMessage
                      defaultMessage="Restrict access"
                      id="object.SELECT_ACCESS_CONTROL"
                    />
                  }
                  labelPlacement="start"
                />
              </Grid>
              {hasAccessControl ? (
                <Grid item md={7} xs={12}>
                  <Autocomplete
                    filterSelectedOptions
                    getOptionLabel={option =>
                      option.name
                        ? intl.formatMessage({
                            id: 'roles.' + option.name.toUpperCase()
                          })
                        : ''
                    }
                    id={ACCESS_CONTROL_LEVEL}
                    name={ACCESS_CONTROL_LEVEL}
                    onChange={(event, value) =>
                      handleDataChange(event, value, ACCESS_CONTROL_LEVEL)
                    }
                    options={roles.filter(r => r.name !== ROLE_OBSERVER)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        label={
                          <FormattedMessage
                            defaultMessage="Role"
                            id="common.ROLE"
                          />
                        }
                        size="small"
                        variant="outlined"
                      />
                    )}
                    size="small"
                    value={role || null}
                  />
                </Grid>
              ) : null}
            </Grid>
          </>
        )}
        {addTemplate ? (
          <>
            <Grid item md={12} xs={12}>
              <Autocomplete
                getOptionLabel={option => option.name || ''}
                id={TEMPLATE}
                name={TEMPLATE}
                onChange={(event, value) =>
                  handleDataChange(event, value, TEMPLATE)
                }
                options={categoryTemplates}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    label={
                      <FormattedMessage
                        defaultMessage="Category Template"
                        id="common.CATEGORY_TEMPLATE"
                      />
                    }
                    size="small"
                    variant="outlined"
                  />
                )}
                size="small"
                value={selected_template || null}
              />
            </Grid>
            {selected_template?.name ? (
              <Grid item md={12} xs={12}>
                <CategoryTemplate
                  categories={document_categories}
                  className={classes.noPadding}
                  prevCategories={prev_categories}
                  updateTemplateValues={updateCategories}
                />
              </Grid>
            ) : null}
            <Grid item md={12} xs={12}>
              <div className={classes.floatRight}>
                <Button
                  className={classes.marginLeft1}
                  color="primary"
                  onClick={() => handleSetTemplate(!addTemplate)}
                  size="small"
                  variant="contained">
                  <FormattedMessage
                    defaultMessage="Cancel"
                    id="common.CANCEL"
                  />
                </Button>
                <Button
                  className={classes.marginLeft1}
                  color="primary"
                  disabled={!selected_template?.name}
                  onClick={copyTemplateToProject}
                  size="small"
                  variant="contained">
                  <FormattedMessage
                    defaultMessage="Copy Template Categories"
                    id="object.COPY_TEMPLATE_CATEGORIES"
                  />
                </Button>
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={12} xs={12}>
              {showRevisionList ? (
                <RevisionList
                  attachments={attachments}
                  error={error}
                  onPopupClose={onPopupClose}
                  setFormState={setFormState}
                  status={submitStatus}
                  verifiedSubmit={verifiedSubmit}
                />
              ) : (
                <Uploader
                  checkDuplicateUpload
                  className={classes.paddingTop2}
                  disableBtns={submitted && !error}
                  dropZoneProps={dropZoneProps}
                  hideDefaultButton
                  onSubmit={addAttachment}
                  uploadPath={uploadPath || getUploadPath(projectId)}
                />
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              <div className={classes.floatRight}>
                {(!showRevisionList && organization_id) ||
                submitStatus === STATUS_ISSUED_FOR_APPROVAL ? (
                  <Button
                    color="secondary"
                    disabled={disableBtns}
                    id="issue-btn"
                    onClick={event =>
                      handleSubmit(event, STATUS_ISSUED_FOR_APPROVAL)
                    }
                    startIcon={
                      duplicateLoading ? <CircularProgress size={20} /> : null
                    }
                    variant="contained">
                    <FormattedMessage
                      defaultMessage="Issue for approval"
                      id="object.ISSUE FOR APPROVAL"
                    />
                  </Button>
                ) : null}
                {(!showRevisionList && !organization_id) ||
                submitStatus === STATUS_PUBLISHED ? (
                  <Button
                    className={classes.marginLeft1}
                    color="primary"
                    disabled={disableBtns}
                    id="publish-btn"
                    onClick={event => handleSubmit(event, STATUS_PUBLISHED)}
                    startIcon={
                      duplicateLoading || submitted ? (
                        <CircularProgress color="secondary" size={20} />
                      ) : null
                    }
                    variant="contained">
                    <FormattedMessage
                      defaultMessage="Publish"
                      id="object.PUBLISH"
                    />
                  </Button>
                ) : null}
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

DocUploader.propTypes = {
  dropZoneProps: PropTypes.object,
  isMultipleUpload: PropTypes.bool,
  isRevision: PropTypes.bool,
  mergeDistribution: PropTypes.bool,
  object: PropTypes.object,
  onPopupClose: PropTypes.func,
  projectId: PropTypes.string,
  reloadObjList: PropTypes.func,
  uploadPath: PropTypes.string
};

DocUploader.defaultProps = {
  isMultipleUpload: false,
  isRevision: false,
  mergeDistribution: false
};

export default DocUploader;
