import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Grid,
  Hidden,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Slide,
  Snackbar,
  SnackbarContent,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  AddCircleOutlined as AddCircleOutlinedIcon,
  ClearAll as ClearAllIcon,
  ExpandMore as ExpandMoreIcon,
  InsertDriveFile as FileIcon,
  PersonAddSharp as AddUserIcon
} from '@material-ui/icons';
import {
  clearCustomNotice,
  clearObjectMessages,
  clearObjectUpdated,
  objectResetBoQ,
  objectsFetch,
  objectsSearch,
  objectStatusFetch,
  objectTypeFetch,
  objectTypeFilterFetch,
  organizationFetch,
  projectFetch,
  setSelectedProjectId,
  settingFetch,
  settingUpdate,
  singleProjectFetch
} from '../../../redux';
import { BoQPreview, InfoPopup, Popup, SearchBox } from 'components';
import { DocUploader, NewObject, NewUser } from '../components';
import { DocumentationTable, ExecutionTable } from './components';
import { ObjectPreview, ObjectView } from '../../Object';
import classNames from 'classnames';
import clsx from 'clsx';
import {
  clearObjectFilter,
  getKeysFromSearchParams,
  isCurrentOrg,
  isDesktopView,
  isMobileView,
  isObjectEmpty,
  serializeParams,
  valueInArray
} from 'common/helper';
import { formatBoQTable } from 'common/boqParser';
import { getDisplayPostnr, getTopAccessLevel } from '../components/helper';
import { getComparator, stableSort } from 'common/sorting';
import { useStyles } from '../styles';
import {
  API_RESPONSE_NO_ACCESS_CHANGE_CATEGORY,
  BOQ,
  CATEGORY_ID,
  CATEGORY_OPTION,
  COMMUNICATION,
  COMMUNICATION_TYPES,
  DISCIPLINE_TYPE_ID,
  DOCUMENTATION,
  DRAWER_LEFT_OPEN,
  DRAWER_RIGHT_OPEN,
  DRAWER_VARIANT_PERSISTENT,
  DRAWER_VARIANT_TEMPORARY,
  LS_ORGANIZATION_ID,
  MAX_FILE_COUNT,
  NAME,
  OBJECT,
  OBJECT_BOQ,
  OBJECT_COLUMN_ID_LAST_UPDATED,
  OBJECT_DISCIPLINE,
  OBJECT_DOCUMENTATION,
  OBJECT_ICO,
  OBJECT_TYPE_ID,
  ORGANIZATION,
  ORGANIZATION_IDS,
  POPOVER_SELECT,
  PROJECT,
  ROLE_OBSERVER,
  ROLE_PARTICIPANT,
  ROLE_PL,
  SORT_ORDER_ASC,
  SORT_ORDER_DESC,
  SS_FILTER_MY_ACTIONS,
  SS_FILTER_PARAMS,
  SS_FILTER_SEARCH,
  SS_FILTER_UNSHARED,
  SS_FILTERS,
  SS_PAGE_NUMBER,
  SS_SELECTED_BOQ,
  SS_SELECTED_CATEGORY,
  SS_SORT_ORDER,
  SS_SORT_ORDER_BY,
  SS_STORED_PROJECT_ID,
  STATUS_ID,
  STATUS_SENT,
  STATUS_SENT_FOR_FILTER,
  STATUS_UNSHARED,
  SUBCATEGORY_ID
} from 'common/constants';

const ObjectList = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  // commenting this for now since we'll be supporting multiple orgs per user in the future
  // force to organization list or project list if missing data
  // if (!localStorage.getItem(LS_ORGANIZATION_ID)) history.push('/organizations');
  if (!localStorage.getItem(LS_ORGANIZATION_ID)) history.push('/projects');

  const orgId = localStorage.getItem(LS_ORGANIZATION_ID);
  const {
    object,
    object: {
      boqObject,
      customNotice,
      filterTypes: objectFilterTypes,
      loading: objectLoading,
      objects: projectObjects,
      objectView,
      statusAll: objectStatusAll,
      types: objectTypes,
      updated: objectUpdated
    },
    organization: { organizations, projectInvite },
    project: {
      loading: projectLoading,
      projects,
      projectSelected,
      projectSelectedId
    },
    screen: { isMobileDevice, screenWidth },
    user: {
      info: { id: userId },
      setting: userSettings
    }
  } = useSelector(state => state);
  const projectOrganizations = projectSelected?.organizations || [];
  const organizationInfo = projectOrganizations.find(org =>
    isCurrentOrg(org.id)
  );
  const projectId =
    projectSelected.id ||
    projectSelectedId ||
    sessionStorage.getItem(SS_STORED_PROJECT_ID);
  const isProjectArchived = projectSelected.archived;
  const projectUsers = organizationInfo?.users || [];
  const isUserPL =
    projectUsers
      .find(u => u.id === userId)
      ?.project_settings.some(ps => ps.role.name === ROLE_PL) || false;
  const isPageNotLoading = !objectLoading && !projectLoading;
  const orgUsers = organizations[0]?.users || [];
  const isDesktop = isDesktopView(screenWidth);
  const drawerVariant = isMobileView(screenWidth)
    ? DRAWER_VARIANT_TEMPORARY
    : DRAWER_VARIANT_PERSISTENT;
  const [state, setState] = useState({
    drawerLeftMaximize: true,
    drawerLeftOpen: isDesktop,
    drawerRightOpen: false,
    filterTypeArr: [],
    filterUnshared: sessionStorage.getItem(SS_FILTER_UNSHARED)
      ? JSON.parse(sessionStorage.getItem(SS_FILTER_UNSHARED))
      : false,
    isActionOwner: sessionStorage.getItem(SS_FILTER_MY_ACTIONS)
      ? JSON.parse(sessionStorage.getItem(SS_FILTER_MY_ACTIONS))
      : false,
    objectId: null,
    order: sessionStorage.getItem(SS_SORT_ORDER) || SORT_ORDER_DESC,
    orderBy:
      sessionStorage.getItem(SS_SORT_ORDER_BY) || OBJECT_COLUMN_ID_LAST_UPDATED,
    orgFilterArr: [],
    pageNo: sessionStorage.getItem(SS_PAGE_NUMBER) || 0,
    searchFilterText: sessionStorage.getItem(SS_FILTER_SEARCH) || '',
    selectedBoQ: sessionStorage.getItem(SS_SELECTED_BOQ)
      ? JSON.parse(sessionStorage.getItem(SS_SELECTED_BOQ))
      : {},
    selectedCategory: sessionStorage.getItem(SS_SELECTED_CATEGORY) || OBJECT,
    showSuccessMessage: false,
    statusFilterArr: [],
    topAccessLevel: ROLE_OBSERVER
  });
  const {
    drawerLeftMaximize,
    drawerLeftOpen,
    drawerRightOpen,
    filterTypeArr,
    filterUnshared,
    isActionOwner,
    objectId,
    objectType,
    order,
    orderBy,
    orgFilterArr,
    searchFilterText,
    selectedBoQ,
    selectedCategory,
    showSuccessMessage,
    statusFilterArr,
    topAccessLevel
  } = state;
  const isDocumentation = selectedCategory === DOCUMENTATION;
  const isBoQ = selectedCategory === BOQ;
  const organizationBoQ = isBoQ
    ? projectOrganizations.filter(
        org =>
          isCurrentOrg(org.parent_organization?.id) || // org is child of user's org
          org.id === organizationInfo.parent_organization?.id // org is parent of user's org
      )
    : [];
  const storedFilters = JSON.parse(sessionStorage.getItem(SS_FILTERS));
  const defaultFilters = {
    object_type_id: [],
    status_id: [],
    discipline_type_id: [],
    category_id: [],
    subcategory_id: [],
    organization_ids: []
  };
  let initFilters = { ...defaultFilters };
  if (storedFilters) initFilters = { ...initFilters, ...storedFilters };

  const [filters, setFilters] = useState(initFilters);
  const storedFilterParams = JSON.parse(
    sessionStorage.getItem(SS_FILTER_PARAMS)
  );
  let initFilterParams = {
    filterVals: [],
    org: [],
    doc_statuses: []
  };
  if (
    storedFilterParams?.filterVals?.length > 0 ||
    storedFilterParams?.org?.length > 0 ||
    storedFilterParams?.doc_statuses?.length > 0
  )
    initFilterParams = storedFilterParams;

  const [filterParams, setFilterParams] = useState(initFilterParams);

  //////////////////////////////////////////////////////////////////////////////
  //                      START OF USEEFFECT FUNCTIONS                        //
  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (history.location.search.includes('project_id')) {
      const { boq_id, category, project_id } = getKeysFromSearchParams(
        history.location.search,
        ['project_id', 'category', 'boq_id']
      );

      if (!project_id || project_id === 'undefined') history.push('/projects');

      if (category) {
        const selectedCategory =
          category === 'boq'
            ? BOQ
            : category === 'documentation'
            ? DOCUMENTATION
            : OBJECT;

        if (selectedCategory === 'boq' && boq_id) selectOrganization(boq_id);

        sessionStorage.setItem(SS_SELECTED_CATEGORY, selectedCategory);
        setState(state => ({ ...state, selectedCategory }));
      }

      dispatch(setSelectedProjectId(project_id));
    }

    if (!projectSelectedId) {
      if (sessionStorage.getItem(SS_STORED_PROJECT_ID))
        dispatch(
          setSelectedProjectId(sessionStorage.getItem(SS_STORED_PROJECT_ID))
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(projectFetch(orgId));
    if (objectTypes.length === 0) dispatch(objectTypeFetch());
    if (objectStatusAll.length === 0)
      dispatch(objectStatusFetch({ type: OBJECT }));
    if (organizations.length === 0) dispatch(organizationFetch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (userId && isObjectEmpty(userSettings)) {
      dispatch(settingFetch(userId));
    }
  }, [dispatch, userId, userSettings]);

  useEffect(() => {
    const { organization, object_type, doc_statuses } = objectFilterTypes;
    const filterTypeArrOpts = {
      [BOQ]: () => object_type[0]?.statuses || [],
      [DOCUMENTATION]: () =>
        object_type.reduce((res, filter) => {
          if (filter.category)
            res.push({
              id: filter.category.id,
              name: filter.category.name,
              children: filter.category.children
            });
          return res;
        }, []),
      [OBJECT]: () =>
        object_type.filter(filterType =>
          COMMUNICATION_TYPES.includes(filterType.name)
        ) || []
    };

    sessionStorage.setItem(SS_PAGE_NUMBER, 0);
    setState(state => ({
      ...state,
      filterTypeArr: filterTypeArrOpts[selectedCategory](),
      orgFilterArr: organization,
      statusFilterArr: doc_statuses || []
    }));
  }, [objectFilterTypes, selectedCategory]);

  useEffect(() => {
    if (projectInvite && !projectInvite.error) {
      if (projectSelectedId) dispatch(singleProjectFetch(projectSelectedId));
      dispatch(organizationFetch());
      setState(state => ({ ...state, showSuccessMessage: true }));
    }
  }, [projectInvite, projectSelectedId, dispatch]);

  useEffect(() => {
    if (projectSelectedId) {
      dispatch(singleProjectFetch(projectSelectedId));
      dispatch(clearObjectMessages());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSelectedId]);

  useEffect(() => {
    if (projectSelected.roles) {
      setState(state => ({
        ...state,
        topAccessLevel: getTopAccessLevel(projectSelected.roles)
      }));
    }
  }, [projectSelected.roles]);

  useEffect(() => {
    if (projectSelectedId && isBoQ && !isObjectEmpty(selectedBoQ))
      dispatch(
        objectTypeFilterFetch(
          serializeParams({
            project_id: projectSelectedId,
            type: BOQ,
            ...selectedBoQ
          })
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBoQ]);

  useEffect(() => {
    if (isBoQ) filterBoQDispatch();
    else filterDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterParams,
    filters,
    filterUnshared,
    organizationBoQ.length,
    projectSelectedId,
    selectedCategory,
    isActionOwner
  ]);

  useEffect(() => {
    // when doc object status is updated
    if (objectUpdated) {
      filterDispatch();
      // TODO: Figure out how to set this with existing functions
      // in a way that it doesn't change the behavior of other callers
      // so that separate call can be removed
      dispatch(clearObjectUpdated());
      if (objectId !== objectView.id)
        setState(state => ({ ...state, objectId: objectView.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectUpdated, dispatch]);

  useEffect(() => {
    setState(state => ({
      ...state,
      drawerLeftOpen: state.drawerLeftOpen || isDesktop
    }));
  }, [isDesktop, drawerLeftOpen]);

  useEffect(() => {
    if (
      sessionStorage.getItem(SS_PAGE_NUMBER) > 0 &&
      projectObjects.total <= 50
    ) {
      sessionStorage.setItem(SS_PAGE_NUMBER, 0);
      refreshObjList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectObjects.total]);

  useEffect(() => {
    if (
      projectSelectedId &&
      projects.length > 0 &&
      !projects.find(project => project.id === projectSelectedId)
    )
      history.push('/projects');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, projectSelectedId]);

  // useEffect(() => {
  //   setState(state => ({
  //     ...state,
  //     drawerLeftMaximize: !isBoQ || !isDesktop
  //   }));
  // }, [isBoQ, isDesktop]);

  //////////////////////////////////////////////////////////////////////////////
  //                        END OF USEEFFECT FUNCTIONS                        //
  //                         START OF LOCAL FUNCTIONS                         //
  //////////////////////////////////////////////////////////////////////////////

  const goToObjectInfo = (id, category = OBJECT) => {
    history.push(`/${category}/${id}?project_id=${projectId}`, {
      projectId: projectId
    });
  };

  const goToBoQObject = (boqId, item) => {
    const id = item.object?.id;
    const { object, ...boqData } = item;
    const params = {
      disciplineTypeId: selectedBoQ.client_organization_id,
      objectType: objectTypes.find(ot => ot.ref_name === OBJECT_BOQ),
      projectId: projectId,
      boqId: boqId,
      boq: boqData
    };
    if (!id) {
      window.alert(intl.formatMessage({ id: 'object.BOQ_CREATION' }));
      history.push('/object/new', {
        ...params,
        title: `${getDisplayPostnr(item)} ${item.description}`,
        created_by_org_id: selectedBoQ.contractor_organization_id
      });
    } else history.push(`/object/${id}?project_id=${projectId}`, params);
  };

  const displayObjectView = (id, objType) => {
    setState(state => ({
      ...state,
      drawerRightOpen: true,
      objectId: id,
      objectType: objType ? objType.toLowerCase() : ''
    }));
  };

  const refreshObjList = (params = {}) => {
    dispatch(
      objectsFetch(
        projectSelectedId || projectId,
        serializeParams({
          type: selectedCategory,
          filters: getObjectFetchFilter(),
          sort_by: orderBy,
          sort_order: order,
          ...(isBoQ ? selectedBoQ : {}),
          ...(isDocumentation && { filter_unshared: filterUnshared }),
          ...(!isDocumentation &&
            !isBoQ && {
              action_owner: isActionOwner
            }),
          ...params,
          page: params?.page || sessionStorage.getItem(SS_PAGE_NUMBER) || 0
        })
      )
    );
  };

  const search = (event, searchFilterText, page) => {
    if (event) event.preventDefault();
    if (searchFilterText)
      dispatch(
        objectsSearch(
          serializeParams({
            page: page || sessionStorage.getItem(SS_PAGE_NUMBER) || 0,
            project_id: projectSelectedId,
            query: searchFilterText,
            type: selectedCategory,
            filters: getObjectFetchFilter(),
            sort_by: orderBy,
            sort_order: order,
            ...(isBoQ ? selectedBoQ : {}),
            ...(isDocumentation && { filter_unshared: filterUnshared }),
            ...(!isDocumentation &&
              !isBoQ && {
                action_owner: isActionOwner
              })
          })
        )
      );
    else refreshObjList({ page });
  };

  const setSearchText = value => {
    sessionStorage.setItem(SS_FILTER_SEARCH, value);
    setState(state => ({ ...state, searchFilterText: value }));
  };

  const setIsActionOwner = event => {
    sessionStorage.setItem(SS_FILTER_MY_ACTIONS, event.target.checked);
    setState(state => ({
      ...state,
      isActionOwner: !state.isActionOwner
    }));
  };

  const setUnsharedFilter = event => {
    sessionStorage.setItem(SS_FILTER_UNSHARED, event.target.checked);
    setState(state => ({
      ...state,
      filterUnshared: event.target.checked
    }));
  };

  const clearSearch = () => {
    setSearchText('');
    search();
  };

  const filterDispatch = params => {
    if (projectSelectedId && selectedCategory) {
      if (!isBoQ)
        dispatch(
          objectTypeFilterFetch(
            serializeParams({
              project_id: projectSelectedId,
              type: isDocumentation ? DOCUMENTATION : COMMUNICATION
            })
          )
        );

      if (searchFilterText) search(null, searchFilterText, params?.page);
      else refreshObjList(params);
    }
  };

  const filterBoQDispatch = () => {
    if (organizationBoQ.length > 0) {
      let organization;
      if (!isObjectEmpty(selectedBoQ)) {
        const {
          client_organization_id,
          contractor_organization_id
        } = selectedBoQ;
        const cId = isCurrentOrg(contractor_organization_id)
          ? client_organization_id
          : contractor_organization_id;
        organization = organizationBoQ.find(o => o.id === cId);
      }
      selectOrganization(organization?.id || organizationBoQ[0]?.id);
    } else setState(state => ({ ...state, filterTypeArr: [] }));
  };

  const getObjectFetchFilter = () => {
    const { filterVals, org, doc_statuses } = filterParams;
    let res = filterVals.reduce((acc, filter) => {
      const val = isDocumentation
        ? filter[CATEGORY_ID]
        : isBoQ
        ? filter[STATUS_ID]
        : filter[OBJECT_TYPE_ID];
      let newFilters = { ...filter };
      if (val) {
        if (org.length > 0) newFilters.sub_filter.organization_ids = org;
        if (isDocumentation) {
          newFilters[CATEGORY_ID] = val.substr(val.lastIndexOf('_') + 1);
          if (doc_statuses.length > 0)
            newFilters.sub_filter.status_id = doc_statuses;
        } else if (isBoQ)
          newFilters[STATUS_ID] = val.substr(val.lastIndexOf('_') + 1);
        acc.push(newFilters);
      }
      return acc;
    }, []);

    if (res.length === 0) {
      if (org.length > 0 && doc_statuses.length > 0)
        res = doc_statuses.reduce((acc, val) => {
          acc.push({
            status_id: val,
            sub_filter: { organization_ids: org }
          });
          return acc;
        }, []);
      else if (org.length > 0) res = [{ organization_ids: org }];
      else if (doc_statuses.length > 0) res = [{ status_id: doc_statuses }];
    }

    return res;
  };

  const toggleTypeFilter = (id, type) => {
    if (type === CATEGORY_ID || type === SUBCATEGORY_ID)
      return id
        ? filterParams.filterVals.findIndex(
            item => item[CATEGORY_ID]?.substr(0, id.length) === id
          ) !== -1
        : false;
    else
      return (
        filterParams.filterVals.findIndex(item => item[type] === id) !== -1
      );
  };

  const clearFilters = () => {
    /* TODO: update this to clear only selected category filter */
    clearObjectFilter();
    setState(state => ({
      ...state,
      filterUnshared: false,
      isActionOwner: false,
      searchFilterText: ''
    }));
    setFilters(defaultFilters);
    setFilterParams(s => ({ ...s, filterVals: [], doc_statuses: [], org: [] }));
  };

  const updateFilterParams = (event, type, id, name) => {
    //used for objectFetchWithFilter parameters
    const filterType = isDocumentation
      ? CATEGORY_ID
      : isBoQ
      ? STATUS_ID
      : OBJECT_TYPE_ID;
    let { filterVals: filterArr, doc_statuses, org } = filterParams;
    if (event.target.checked) {
      if (
        valueInArray([CATEGORY_ID, OBJECT_TYPE_ID], type) ||
        (isBoQ && type === STATUS_ID)
      ) {
        filterArr.push({
          [type]: id,
          sub_filter: {}
        });
      } else if (type === ORGANIZATION_IDS) {
        org.push(id);
      } else if (isDocumentation && type === STATUS_ID) {
        if (name === STATUS_UNSHARED) return setUnsharedFilter(event);
        else doc_statuses.push(id);
      } else if (type === SUBCATEGORY_ID) {
        let idx = filterArr.findIndex(
          item => item[filterType] === id.substr(0, id.lastIndexOf('_'))
        );
        if (idx >= 0) filterArr[idx][filterType] = event.target.name;
        else {
          idx = filterArr.findIndex(
            item =>
              item[filterType].substr(0, item[filterType].indexOf('_')) ===
              id.substr(0, id.indexOf('_'))
          );
          filterArr.push({
            [filterType]: id,
            sub_filter: idx < 0 ? {} : filterArr[idx].sub_filter
          });
        }
      } else {
        const idx = filterArr.findIndex(item => {
          let category = item[filterType];
          if (category.indexOf('_') > 0)
            category = category.substr(0, category.indexOf('_'));
          return category === id;
        });
        if (idx >= 0) {
          filterArr[idx].sub_filter[type] = filterArr[idx].sub_filter[type]
            ? filterArr[idx].sub_filter[type]
            : [];
          filterArr[idx].sub_filter[type].push(event.target.name);
        }
      }
    } else {
      if (type === OBJECT_TYPE_ID) {
        if (filterArr)
          filterArr.splice(
            filterArr.findIndex(item => item[type] === id),
            1
          );
      } else if (type === ORGANIZATION_IDS) {
        org.splice(
          org.findIndex(o => o === id),
          1
        );
      } else if (isDocumentation && type === STATUS_ID) {
        if (name === STATUS_UNSHARED) return setUnsharedFilter(event);
        else
          doc_statuses.splice(
            doc_statuses.findIndex(o => o === id),
            1
          );
      } else if (type === CATEGORY_ID || type === SUBCATEGORY_ID) {
        filterArr
          .filter(item => item[filterType]?.startsWith(id))
          .forEach(val => {
            const idx = filterArr.findIndex(
              item => item[filterType] === val[filterType]
            );
            const newCategoryId = id.substr(0, id.lastIndexOf('_'));
            if (
              newCategoryId &&
              !filterArr.some(
                (f, i) => f[filterType].startsWith(newCategoryId) && i !== idx
              )
            ) {
              filterArr[idx][filterType] = newCategoryId;
            } else {
              filterArr.splice(idx, 1);
            }
          });
      } else if (isBoQ && type === STATUS_ID) {
        if (filterArr)
          filterArr.splice(
            filterArr.findIndex(item => item[type] === id),
            1
          );
      } else {
        //if status or discipline
        filterArr.forEach((item, idx) => {
          const booleanRes = isDocumentation
            ? item[filterType].startsWith(id)
            : item[filterType] === id;
          if (booleanRes && filterArr[idx].sub_filter[type])
            filterArr[idx].sub_filter[type].splice(
              filterArr[idx].sub_filter[type].indexOf(event.target.name),
              1
            );
        });
      }
    }
    const newFilterParams = {
      ...filterParams,
      filterVals: filterArr,
      doc_statuses: doc_statuses,
      org: org
    };
    setFilterParams(newFilterParams);
    sessionStorage.setItem(SS_FILTER_PARAMS, JSON.stringify(newFilterParams));
  };

  const filter = (event, type, id, name) => {
    updateFilterParams(event, type, id, name);
    const arr = filters[type];
    let filterUpdates = {};
    if (event.target.checked) arr.push(`${id}_${event.target.name}`);
    else {
      arr.splice(arr.indexOf(`${id}_${event.target.name}`), 1);
      if (type === OBJECT_TYPE_ID) {
        const status_arr = filters.status_id;
        const disc_arr = filters.discipline_type_id;
        filterUpdates = {
          status_id: status_arr.filter(item => item.indexOf(id) !== 0),
          discipline_type_id: disc_arr.filter(item => item.indexOf(id) !== 0)
        };
      } else if (type === CATEGORY_ID) {
        const sub_cat_arr = filters.subcategory_id;
        const status_arr = filters.status_id;
        filterUpdates = {
          subcategory_id: sub_cat_arr.filter(item => item.indexOf(id) !== 0),
          status_id: status_arr.filter(item => item.indexOf(id) !== 0)
        };
      }
    }

    setFilters(filters => ({
      ...filters,
      [type]: arr,
      ...filterUpdates
    }));
    sessionStorage.setItem(
      SS_FILTERS,
      JSON.stringify({
        ...filters,
        [type]: arr,
        ...filterUpdates
      })
    );
  };

  const closeDrawers = () => {
    if (isMobileView(screenWidth))
      setState(state => ({
        ...state,
        drawerLeftOpen: false,
        drawerRightOpen: false
      }));
  };

  const toggleDrawer = drawer =>
    setState(state => ({
      ...state,
      [drawer]: !state[drawer]
    }));

  const toggleDrawerSize = () =>
    setState(state => ({
      ...state,
      drawerLeftMaximize: !state.drawerLeftMaximize
    }));

  const handleChangePage = (_evt, newPage) => {
    sessionStorage.setItem(SS_PAGE_NUMBER, newPage);
    filterDispatch({
      page: newPage,
      sort_by: orderBy,
      sort_order: order
    });
  };

  const setTableSort = property => {
    const isAsc = orderBy === property && order === SORT_ORDER_ASC;
    const orderValue = isAsc ? SORT_ORDER_DESC : SORT_ORDER_ASC;
    sessionStorage.setItem(SS_SORT_ORDER, orderValue);
    sessionStorage.setItem(SS_SORT_ORDER_BY, property);

    setState(state => ({
      ...state,
      order: orderValue,
      orderBy: property
    }));

    refreshObjList({
      type: isDocumentation ? DOCUMENTATION : COMMUNICATION,
      page: projectObjects.page,
      sort_by: property,
      sort_order: orderValue
    });
  };

  const [anchors, setAnchors] = useState({
    category: null,
    organization: null,
    project: null
  });
  const handleAnchorAction = (anchor, value = null) =>
    setAnchors(anchors => ({ ...anchors, [anchor]: value }));

  const isCategoryAnchor = Boolean(anchors.category);
  const selectViewId = isCategoryAnchor ? POPOVER_SELECT : undefined;
  const isOrganizationAnchor = Boolean(anchors.organization);
  const selectOrganizationId = isOrganizationAnchor
    ? POPOVER_SELECT
    : undefined;
  const isProjectAnchor = Boolean(anchors.project);
  const selectProjectId = isProjectAnchor ? POPOVER_SELECT : undefined;

  const selectProject = id => {
    if (id !== projectSelectedId) {
      clearFilters();
      setState(state => ({
        ...state,
        projectSelectedId: id,
        topAccessLevel: ROLE_OBSERVER
      }));
      dispatch(setSelectedProjectId(id));
      dispatch(objectResetBoQ());
      if (drawerRightOpen) toggleDrawer(DRAWER_RIGHT_OPEN);
    }
    handleAnchorAction(PROJECT);
  };

  const selectCategory = category => {
    if (selectedCategory !== category) {
      clearFilters();
      setState(state => ({
        ...state,
        selectedCategory: category,
        // reset side menu filter option(s)
        filterTypeArr: [],
        orgFilterArr: [],
        statusFilterArr: [],
        // reset sorting option(s)
        order: SORT_ORDER_DESC,
        orderBy: OBJECT_COLUMN_ID_LAST_UPDATED
      }));
      sessionStorage.setItem(SS_SELECTED_CATEGORY, category);
      handleAnchorAction(CATEGORY_OPTION);
      if (drawerRightOpen) toggleDrawer(DRAWER_RIGHT_OPEN);
    }
  };

  const selectOrganization = (organization_id, clearFilter = false) => {
    const org = organizationBoQ.find(boq => boq.id === organization_id);
    const isCurrOrg = isCurrentOrg(org.parent_organization?.id);
    const selectedBoQ = {
      client_organization_id: isCurrOrg ? orgId : org.id,
      contractor_organization_id: isCurrOrg ? org.id : orgId,
      name: org.name
    };

    sessionStorage.setItem(SS_SELECTED_BOQ, JSON.stringify(selectedBoQ));
    setState(state => ({ ...state, selectedBoQ }));
    if (clearFilter) clearFilters();
    else
      filterDispatch({
        client_organization_id: selectedBoQ.client_organization_id,
        contractor_organization_id: selectedBoQ.contractor_organization_id
      });
    handleAnchorAction(ORGANIZATION);
  };

  const getChildren = (category, key) => {
    const level = key ? key.split('_').length + 1 : 1;
    return (
      <Collapse
        in={
          category.children?.length > 0 &&
          toggleTypeFilter(
            key,
            key === category.id ? CATEGORY_ID : SUBCATEGORY_ID
          )
        }>
        <List component="div" disablePadding>
          {category.children &&
            category.children.map((subCategory, idx) => {
              const newName = `${key}_${subCategory.id}`;
              return (
                <div key={`docChildItm_${key}_${idx}`}>
                  <ListItem
                    style={{ paddingLeft: 16 * level }}
                    component="div"
                    dense>
                    <ListItemIcon className={classes.filterItemIcon}>
                      <Checkbox
                        checked={filters.subcategory_id.includes(
                          `${newName}_${newName}`
                        )}
                        className={classNames(
                          classes.filters,
                          classes.noMargin
                        )}
                        color="primary"
                        edge="start"
                        key={`docSubChk_${newName}`}
                        name={newName}
                        onChange={e => filter(e, SUBCATEGORY_ID, newName)}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography className={classes.filterListItemText}>
                          {subCategory.name}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {getChildren(subCategory, newName)}
                </div>
              );
            })}
        </List>
      </Collapse>
    );
  };

  const handleInfoPopupClose = () => dispatch(clearCustomNotice());

  const categoryViewOpts = {
    [BOQ]: () => ({
      filterLabel: (
        <FormattedMessage defaultMessage="Status" id="common.STATUS" />
      ),
      sidebarTitle: (
        <FormattedMessage
          defaultMessage="Bill of Quantities"
          id="object.BILL_OF_QUANTITIES"
        />
      )
    }),
    [DOCUMENTATION]: () => ({
      filterLabel: (
        <FormattedMessage defaultMessage="Category" id="common.CATEGORY" />
      ),
      newObjLabel: (
        <FormattedMessage defaultMessage="Upload" id="common.UPLOAD" />
      ),
      newObjPopup: (
        <DocUploader
          dropZoneProps={{ maxFiles: MAX_FILE_COUNT }}
          isMultipleUpload
          mergeDistribution={true}
          projectId={projectSelectedId}
          reloadObjList={refreshObjList}
        />
      ),
      sidebarTitle: (
        <FormattedMessage
          defaultMessage="Documentation"
          id="object.DOCUMENTATION"
        />
      )
    }),
    [OBJECT]: () => ({
      filterLabel: (
        <FormattedMessage
          defaultMessage="Object type"
          id="object.OBJECT_TYPE"
        />
      ),
      newObjLabel: (
        <FormattedMessage defaultMessage="Add object" id="object.ADD_OBJECT" />
      ),
      myActionsLabel: (
        <FormattedMessage defaultMessage="My actions" id="object.MY_ACTIONS" />
      ),
      newObjPopup: (
        <NewObject
          category={selectedCategory}
          history={history}
          objectTypes={objectTypes.filter(type =>
            COMMUNICATION_TYPES.includes(type.name)
          )}
          projectId={projectSelectedId}
        />
      ),
      sidebarTitle: (
        <FormattedMessage
          defaultMessage="Communication"
          id="object.COMMUNICATION"
        />
      )
    })
  };
  const categoryView = categoryViewOpts[selectedCategory]();
  const comparator = getComparator(SORT_ORDER_ASC, NAME, intl);
  let sortedFilterTypeArr = filterTypeArr.map(ft => {
    // sort status sub filter
    if (ft.statuses?.length > 0)
      ft.statuses = stableSort(ft.statuses, comparator);
    return ft;
  });
  sortedFilterTypeArr = stableSort(sortedFilterTypeArr, comparator); // sort main filters
  const tblProps = {
    displayObjectView,
    goToObjectInfo,
    handleChangePage,
    isMobileDevice,
    isProjectArchived,
    object,
    objectId,
    order,
    orderBy,
    projectLoading,
    setTableSort
  };

  const infoPopupMsg =
    customNotice === API_RESPONSE_NO_ACCESS_CHANGE_CATEGORY ? (
      <Typography>
        {intl.formatMessage({ id: 'info.NO_ACCESS_CHANGE_CATEGORY' })}
      </Typography>
    ) : (
      customNotice
    );

  const dismissIcoNotice = () => {
    dispatch(
      settingUpdate({
        id: userSettings.id,
        user_id: userSettings.user_id,
        settings: {
          ...userSettings.settings,
          ico_notice: undefined // delete the setting
        }
      })
    );
  };

  return (
    <>
      <InfoPopup
        fnClose={handleInfoPopupClose}
        message={infoPopupMsg}
        open={!!customNotice}
      />
      <Drawer
        classes={{
          paper: clsx(classes.drawerPaperLeft, {
            [classes.drawerPaperLeftMaximize]: drawerLeftMaximize,
            [classes.drawerPaperLeftMinimize]: !drawerLeftMaximize
          })
        }}
        ModalProps={{ keepMounted: true }}
        onClose={closeDrawers}
        open={drawerLeftOpen}
        variant={drawerVariant}>
        <div className={classes.filterView}>
          {drawerLeftMaximize ? (
            <Grid alignItems="center" container spacing={2}>
              <Grid item xs={12}>
                <Box
                  className={classes.categorySelect}
                  display="flex"
                  justifyContent="space-between">
                  <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    aria-owns={selectViewId}
                    className={classes.projectSelectMenu}
                    disableFocusRipple
                    disableRipple
                    id="category-select"
                    onClick={e =>
                      handleAnchorAction(CATEGORY_OPTION, e.currentTarget)
                    }
                    variant="text">
                    <Typography
                      className={classes.projectSelectMenu}
                      display="inline"
                      variant="h4">
                      {categoryView.sidebarTitle}
                    </Typography>
                    <ExpandMoreIcon size="small" />
                  </IconButton>
                  <Popover
                    anchorEl={anchors.category}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    id={POPOVER_SELECT}
                    onClose={() => handleAnchorAction(CATEGORY_OPTION)}
                    open={isCategoryAnchor}>
                    <MenuList id="simple-menu" open={isCategoryAnchor}>
                      <MenuItem
                        id="category-item-communication"
                        key="key-execution"
                        onClick={() => selectCategory(OBJECT)}>
                        <Typography className={classes.projectSelectMenu}>
                          <FormattedMessage
                            defaultMessage="Communication"
                            id="object.COMMUNICATION"
                          />
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        id="category-item-documentation"
                        key="key-documention"
                        onClick={() => selectCategory(DOCUMENTATION)}>
                        <Typography className={classes.projectSelectMenu}>
                          <FormattedMessage
                            defaultMessage="Documentation"
                            id="object.DOCUMENTATION"
                          />
                        </Typography>
                      </MenuItem>
                      {valueInArray(
                        [ROLE_OBSERVER, ROLE_PARTICIPANT],
                        topAccessLevel
                      ) ? null : (
                        <MenuItem
                          id="category-item-boq"
                          key="key-boq"
                          onClick={() => selectCategory(BOQ)}>
                          <Typography className={classes.projectSelectMenu}>
                            <FormattedMessage
                              defaultMessage="Bill of Quantities"
                              id="object.BILL_OF_QUANTITIES"
                            />
                          </Typography>
                        </MenuItem>
                      )}
                    </MenuList>
                  </Popover>
                  {isDesktop ? null : (
                    <>
                      <Hidden mdDown>
                        <IconButton onClick={toggleDrawerSize} size="small">
                          <Icon style={{ transform: 'rotateY(180deg)' }}>
                            <img alt="" src="/images/double_arrow.svg" />
                          </Icon>
                        </IconButton>
                      </Hidden>
                      <Hidden lgUp>
                        <IconButton
                          onClick={() => toggleDrawer(DRAWER_LEFT_OPEN)}
                          size="small">
                          <Icon style={{ transform: 'rotateY(180deg)' }}>
                            <img alt="" src="/images/double_arrow.svg" />
                          </Icon>
                        </IconButton>
                      </Hidden>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <SearchBox
                  onChange={setSearchText}
                  onClearButtonClick={clearSearch}
                  onSearchButtonClick={search}
                  onSubmit={search}
                  searchText={searchFilterText}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between">
                  {isDocumentation || isBoQ ? (
                    <Typography className={classes.filterLabel}>
                      {categoryView.filterLabel}
                    </Typography>
                  ) : (
                    <Typography className={classes.filterLabel}>
                      <Checkbox
                        checked={isActionOwner}
                        className={classNames(
                          classes.filters,
                          classes.noMargin
                        )}
                        color="primary"
                        edge="start"
                        key="chk_my_actions"
                        name="my_actions"
                        onChange={e => setIsActionOwner(e)}
                      />
                      {categoryView.myActionsLabel}
                    </Typography>
                  )}
                  <Tooltip
                    arrow
                    title={
                      <FormattedMessage
                        defaultMessage="Clear filters"
                        id="common.CLEAR_SS_FILTERS_AND_SORT"
                      />
                    }>
                    <IconButton onClick={clearFilters} size="small">
                      <ClearAllIcon
                        className={classes.link}
                        color="secondary"
                        variant="button"
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
              {!isDocumentation && !isBoQ ? (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between">
                      <Typography className={classes.filterLabel}>
                        {categoryView.filterLabel}:
                      </Typography>
                    </Box>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <List component="div" disablePadding key="object-type-list">
                  {sortedFilterTypeArr.map(filterType => (
                    <div key={`itm_${filterType.id}`}>
                      <ListItem component="div" dense>
                        <ListItemIcon className={classes.filterItemIcon}>
                          <Checkbox
                            checked={
                              filters.object_type_id.includes(
                                `${filterType.id}_${filterType.id}`
                              ) ||
                              filters.category_id.includes(
                                `${filterType.id}_${filterType.id}`
                              ) ||
                              filters.status_id.includes(
                                `${filterType.id}_${filterType.id}`
                              )
                            }
                            className={classNames(
                              classes.filters,
                              classes.noMargin
                            )}
                            color="primary"
                            edge="start"
                            key={`chk_${filterType.id}`}
                            name={filterType.id}
                            onChange={e =>
                              filter(
                                e,
                                isDocumentation
                                  ? CATEGORY_ID
                                  : isBoQ
                                  ? STATUS_ID
                                  : OBJECT_TYPE_ID,
                                filterType.id
                              )
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={filterType.id}
                          primary={
                            <Typography className={classes.filterListItemText}>
                              <FormattedMessage
                                defaultMessage={filterType.name}
                                id={'object.' + filterType.name.toUpperCase()}
                              />
                              &nbsp;&nbsp;
                              <b>{isBoQ ? `(${filterType.count})` : ''}</b>
                            </Typography>
                          }
                        />
                      </ListItem>
                      {getChildren(filterType, filterType.id)}
                      <Collapse
                        in={
                          (toggleTypeFilter(filterType.id, OBJECT_TYPE_ID) ||
                            toggleTypeFilter(filterType.id, CATEGORY_ID)) &&
                          filterType.statuses?.length > 0
                        }>
                        {!isDocumentation ? (
                          <List component="div" disablePadding>
                            <ListItem className={classes.nested}>
                              <ListItemText
                                primary={
                                  <Typography
                                    className={classes.filterListItemText}>
                                    <FormattedMessage
                                      defaultMessage={'Status'}
                                      id={'common.STATUS'}
                                    />
                                    :
                                  </Typography>
                                }
                              />
                            </ListItem>
                            {filterType.statuses?.map(s => (
                              <ListItem
                                className={classes.nested}
                                component="div"
                                dense
                                key={`subItm_${s.id}`}>
                                <ListItemIcon
                                  className={classes.filterItemIcon}>
                                  <Checkbox
                                    checked={filters.status_id.includes(
                                      `${filterType.id}_${s.id}`
                                    )}
                                    className={classNames(
                                      classes.filters,
                                      classes.noMargin
                                    )}
                                    color="primary"
                                    edge="start"
                                    key={`subChk_${s.id}`}
                                    name={s.id}
                                    onChange={e =>
                                      filter(e, STATUS_ID, filterType.id)
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography
                                      className={classes.filterListItemText}>
                                      <FormattedMessage
                                        defaultMessage={s.name}
                                        id={
                                          'object.' +
                                          (s.name === STATUS_SENT
                                            ? STATUS_SENT_FOR_FILTER
                                            : s.name.toUpperCase())
                                        }
                                      />
                                      &nbsp;&nbsp;<b>({s.count})</b>
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        ) : null}
                      </Collapse>
                      <Collapse
                        in={
                          filterType.name === OBJECT_ICO &&
                          toggleTypeFilter(filterType.id, OBJECT_TYPE_ID)
                        }>
                        <Divider
                          className={classes.dividerMarginTop}
                          variant="middle"
                        />
                        <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                            <ListItemText
                              primary={
                                <Typography
                                  className={classes.filterListItemText}>
                                  <FormattedMessage
                                    defaultMessage={OBJECT_DISCIPLINE}
                                    id={'common.DISCIPLINE'}
                                  />
                                  :
                                </Typography>
                              }
                            />
                          </ListItem>
                          {filterType.disciplineTypes
                            ? filterType.disciplineTypes.map(disciplineType => (
                                <ListItem
                                  className={classes.nested}
                                  component="div"
                                  dense
                                  key={`discSubItm_${disciplineType.id}`}>
                                  <ListItemIcon
                                    className={classes.filterItemIcon}>
                                    <Checkbox
                                      checked={filters.discipline_type_id.includes(
                                        `${filterType.id}_${disciplineType.id}`
                                      )}
                                      className={classNames(
                                        classes.filters,
                                        classes.noMargin
                                      )}
                                      color="primary"
                                      edge="start"
                                      key={`discSubChk_${disciplineType.id}`}
                                      name={disciplineType.id}
                                      onChange={e =>
                                        filter(
                                          e,
                                          DISCIPLINE_TYPE_ID,
                                          filterType.id
                                        )
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        className={classes.filterListItemText}>
                                        {disciplineType.name}&nbsp;&nbsp;
                                        <b>({disciplineType.count})</b>
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              ))
                            : null}
                        </List>
                      </Collapse>
                    </div>
                  ))}
                </List>
              </Grid>
              {isDocumentation ? (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between">
                      <Typography className={classes.filterLabel}>
                        <FormattedMessage
                          defaultMessage="Status"
                          id="object.STATUS"
                        />
                        :
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <List component="div" disablePadding key="status-list">
                      {statusFilterArr.map(status => (
                        <div key={`itm_${status.id}`}>
                          <ListItem component="div" dense>
                            <ListItemIcon className={classes.filterItemIcon}>
                              <Checkbox
                                checked={filters.status_id.includes(
                                  `${status.id}_${status.id}`
                                )}
                                className={classNames(
                                  classes.filters,
                                  classes.noMargin
                                )}
                                color="primary"
                                edge="start"
                                key={`chk_${status.id}`}
                                name={status.id}
                                onChange={e =>
                                  filter(e, STATUS_ID, status.id, status.name)
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={status.id}
                              primary={
                                <Typography
                                  className={classes.filterListItemText}>
                                  <FormattedMessage
                                    defaultMessage={status.name}
                                    id={
                                      'object.' +
                                      (status.name === STATUS_SENT
                                        ? STATUS_SENT_FOR_FILTER
                                        : status.name.toUpperCase())
                                    }
                                  />
                                  &nbsp;&nbsp;<b>({status.count})</b>
                                </Typography>
                              }
                            />
                          </ListItem>
                        </div>
                      ))}
                    </List>
                  </Grid>
                </>
              ) : null}
              {!isBoQ ? (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between">
                      <Typography className={classes.filterLabel}>
                        <FormattedMessage
                          defaultMessage="Organization"
                          id="organization.ORGANIZATION"
                        />
                        :
                      </Typography>
                      {/* TODO: add clear filter */}
                      {/*
                        <Tooltip
                          arrow
                          title={
                            <FormattedMessage
                              defaultMessage="Clear filters"
                              id="common.CLEAR_SS_FILTERS_AND_SORT"
                            />
                          }>
                          <IconButton onClick={clearFilters} size="small">
                            <ClearAllIcon
                              className={classes.link}
                              color="secondary"
                              variant="button"
                            />
                          </IconButton>
                        </Tooltip>
                      */}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <List
                      component="div"
                      disablePadding
                      key="organization-list">
                      {orgFilterArr.map(org => (
                        <div key={`itm_${org.id}`}>
                          <ListItem component="div" dense>
                            <ListItemIcon className={classes.filterItemIcon}>
                              <Checkbox
                                checked={filters.organization_ids.includes(
                                  `${org.id}_${org.id}`
                                )}
                                className={classNames(
                                  classes.filters,
                                  classes.noMargin
                                )}
                                color="primary"
                                edge="start"
                                key={`chk_${org.id}`}
                                name={org.id}
                                onChange={e =>
                                  filter(e, ORGANIZATION_IDS, org.id)
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={org.id}
                              primary={
                                <Typography
                                  className={classes.filterListItemText}>
                                  {org.name}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </div>
                      ))}
                    </List>
                  </Grid>
                </>
              ) : null}
            </Grid>
          ) : (
            <IconButton onClick={toggleDrawerSize} size="small">
              <Icon>
                <img alt="" src="/images/double_arrow.svg" />
              </Icon>
            </IconButton>
          )}
        </div>
      </Drawer>
      <main
        className={clsx(classes.content, {
          ...(drawerLeftOpen && {
            [classes.contentShiftLeftMaximize]: drawerLeftMaximize,
            [classes.contentShiftLeftMinimize]: !drawerLeftMaximize
          }),
          ...(drawerLeftOpen &&
            drawerRightOpen && {
              [classes.contentShiftBothMaximize]: drawerLeftMaximize,
              [classes.contentShiftBothMinimize]: !drawerLeftMaximize
            }),
          [classes.contentShiftLeft]: drawerLeftOpen,
          [classes.contentShiftRight]: drawerRightOpen
        })}>
        {!!userSettings?.settings?.ico_notice ? (
          <SnackbarContent
            action={
              <Button
                color="inherit"
                onClick={dismissIcoNotice}
                size="small"
                variant="outlined">
                <FormattedMessage
                  defaultMessage="Dismiss"
                  id="info.DISMISS_MESSAGE"
                />
              </Button>
            }
            className={classes.snackbar}
            message={
              <Alert
                className={classes.alertIco}
                severity="info"
                variant="filled">
                <AlertTitle
                  style={{ marginBottom: 0, textTransform: 'uppercase' }}>
                  <strong>
                    <FormattedMessage
                      defaultMessage="Important notice: The ICO collaboration object is nearing its end of life."
                      id="info.ICO_END_OF_LIFE_HEADER"
                      values={{ br: <br /> }}
                    />
                  </strong>
                </AlertTitle>
                <FormattedMessage
                  defaultMessage="When we move to the new version of Str8line, you will no longer have access to the ICO object, but references will remain visible where the object was used."
                  id="info.ICO_END_OF_LIFE_MESSAGE"
                />
              </Alert>
            }
          />
        ) : null}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          key="archivedProject"
          open={isProjectArchived && !userSettings?.settings?.ico_notice}
          TransitionComponent={Slide}>
          <Alert
            className={classes.archivedProject}
            severity="info"
            variant="filled">
            <AlertTitle
              className={classNames(
                classes.archivedProject,
                classes.alertMessageTitle
              )}>
              <FormattedMessage
                defaultMessage="This Project is archived and will have limited view functionality only. "
                id="common.ARCHIVED_PROJECT_TITLE"
              />
            </AlertTitle>
            <FormattedMessage
              defaultMessage="An administrator can unarchive the project if needed. Note that additional licence fees may apply."
              id="common.ARCHIVED_PROJECT"
            />
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
          key="inviteSuccess"
          onClose={() =>
            setState(state => ({ ...state, showSuccessMessage: false }))
          }
          open={showSuccessMessage}
          TransitionComponent={Slide}>
          <Alert severity="success">
            <FormattedMessage
              defaultMessage="User/s have been added to the project."
              id="project.INVITE_USERS_SUCCESS"
            />
          </Alert>
        </Snackbar>
        <Grid
          alignItems="center"
          className={classNames(classes.marginBottom2, classes.projectHeight)}
          container
          justifyContent="space-between"
          spacing={0}>
          <Grid item>
            <Hidden lgUp>
              <IconButton
                onClick={() => toggleDrawer(DRAWER_LEFT_OPEN)}
                size="small">
                <Icon>
                  <img alt="" src="/images/double_arrow.svg" />
                </Icon>
              </IconButton>
            </Hidden>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              aria-owns={selectProjectId}
              className={classNames(
                classes.marginLeft1,
                classes.projectSelectMenu
              )}
              disableFocusRipple
              disableRipple
              onClick={e => handleAnchorAction(PROJECT, e.currentTarget)}
              variant="text">
              <Typography
                className={classes.projectSelectMenu}
                display="inline"
                variant="h4">
                {projectSelected.name}
              </Typography>
              {projectSelected.name && isPageNotLoading ? (
                <ExpandMoreIcon size="small" />
              ) : null}
            </IconButton>
            <Popover
              anchorEl={anchors.project}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              id={POPOVER_SELECT}
              onClose={() => handleAnchorAction(PROJECT)}
              open={isProjectAnchor}>
              <MenuList id="simple-menu" open={isProjectAnchor}>
                {projects
                  .filter(project => !project.archived)
                  .map((project, key) => {
                    return (
                      <MenuItem
                        key={key}
                        onClick={() => selectProject(project.id)}>
                        <Typography className={classes.projectSelectMenu}>
                          {project.name}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </MenuList>
            </Popover>
          </Grid>
          {isBoQ ? (
            organizationBoQ.length > 0 ? (
              <Grid item>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  aria-owns={selectOrganizationId}
                  className={classes.projectSelectMenu}
                  disableFocusRipple
                  disableRipple
                  onClick={e =>
                    handleAnchorAction(ORGANIZATION, e.currentTarget)
                  }
                  variant="text">
                  <Typography
                    className={classes.projectSelectMenu}
                    display="inline"
                    variant="h5">
                    {selectedBoQ.name || ''}
                  </Typography>
                  <ExpandMoreIcon size="small" />
                </IconButton>
                <Popover
                  anchorEl={anchors.organization}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  id={POPOVER_SELECT}
                  onClose={() => handleAnchorAction(ORGANIZATION)}
                  open={isOrganizationAnchor}>
                  <MenuList id="simple-menu" open={isOrganizationAnchor}>
                    {organizationBoQ.map(option => (
                      <MenuItem
                        key={option.id}
                        onClick={() => selectOrganization(option.id, true)}>
                        <Typography className={classes.projectSelectMenu}>
                          {option.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Popover>
              </Grid>
            ) : null
          ) : (
            <Grid item>
              {(projectObjects?.content &&
                projectObjects?.content.length === 0) ||
              topAccessLevel === ROLE_OBSERVER ||
              isProjectArchived ? null : (
                <Popup
                  button={
                    isMobileDevice ? (
                      <Tooltip title={categoryView.newObjLabel}>
                        <IconButton
                          aria-label="Create new"
                          className={classes.objListIcon}
                          id="new-object-btn"
                          size="small">
                          <FileIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Button
                        aria-label="Create new"
                        color="primary"
                        id="new-object-btn"
                        startIcon={<FileIcon fontSize="small" />}
                        variant="contained">
                        <Typography
                          className={classes.buttonLabel}
                          variant="body2">
                          {categoryView.newObjLabel}
                        </Typography>
                      </Button>
                    )
                  }>
                  {categoryView.newObjPopup}
                </Popup>
              )}
              {isUserPL && !isMobileDevice && !isProjectArchived ? (
                <Popup
                  button={
                    <Tooltip
                      title={
                        <FormattedMessage
                          defaultMessage="Add User"
                          id="object.ADD_USER"
                        />
                      }>
                      <IconButton className={classes.objListIcon} size="small">
                        <AddUserIcon />
                      </IconButton>
                    </Tooltip>
                  }>
                  <NewUser
                    disciplineTypes={organizationInfo?.discipline_types || []}
                    orgUsers={orgUsers}
                    projectId={projectSelectedId}
                    projectOrganizations={projectOrganizations}
                    projectUsers={projectUsers}
                  />
                </Popup>
              ) : null}
            </Grid>
          )}
        </Grid>
        {isBoQ && boqObject ? (
          boqObject.id ? (
            <BoQPreview
              boq={formatBoQTable(boqObject.content, boqObject.project_key)}
              boqId={boqObject.id}
              boqPage={boqObject.page}
              boqTotal={boqObject.total}
              filterDispatch={filterDispatch}
              goToObjectInfo={goToBoQObject}
              isProjectArchived={isProjectArchived}
              selectedBoQ={selectedBoQ}
              sums={boqObject.sums}
            />
          ) : (
            <div className={classes.emptyResultContainer}>
              <Typography>
                <FormattedMessage
                  defaultMessage="No bill of quantities uploaded. Please upload a bill of quantities according to NS 3459"
                  id="object.EMPTY_BOQ"
                />
              </Typography>
            </div>
          )
        ) : projectObjects?.content &&
          projectObjects?.content.length === 0 &&
          topAccessLevel !== ROLE_OBSERVER ? (
          isProjectArchived ? null : (
            <div className={classes.emptyResultContainer}>
              <Popup
                button={
                  <Button
                    className={classes.emptyResultButton}
                    id="empty-list-btn">
                    <AddCircleOutlinedIcon
                      className={classes.addIcon}
                      color="primary"
                      fontSize="large"
                    />
                    <Typography component="div">
                      <Box className={classes.textLarge} textAlign="center">
                        {categoryView.newObjLabel}
                      </Box>
                    </Typography>
                  </Button>
                }>
                {categoryView.newObjPopup}
              </Popup>
            </div>
          )
        ) : isPageNotLoading ? (
          isDocumentation ? (
            <DocumentationTable
              {...tblProps}
              projectName={
                projectSelected?.name ||
                sessionStorage.getItem(SS_STORED_PROJECT_ID)
              }
              projectOrganizations={projectOrganizations}
            />
          ) : (
            <ExecutionTable {...tblProps} />
          )
        ) : null}
      </main>
      <Drawer
        anchor="right"
        classes={{
          paper: classNames(
            classes.drawerPaperRight,
            isMobileDevice && isDocumentation
              ? [classes.fullWidth, classes.noPadding]
              : classes.drawerPaperRightDesktop
          )
        }}
        ModalProps={{ keepMounted: !isMobileDevice }}
        onClose={closeDrawers}
        open={drawerRightOpen}
        variant={drawerVariant}>
        {objectId && objectType ? (
          objectType.includes(OBJECT_DOCUMENTATION.toLowerCase()) ? (
            <ObjectPreview
              goToObjectInfo={goToObjectInfo}
              isProjectArchived={isProjectArchived}
              listView
              objectId={objectId}
              projectId={projectSelectedId}
              toggleDrawer={() => toggleDrawer(DRAWER_RIGHT_OPEN)}
              topAccessLevel={topAccessLevel}
            />
          ) : (
            <ObjectView
              currRevision={objectView.metadata?.revision}
              displayObjectView={displayObjectView}
              goToObjectInfo={goToObjectInfo}
              histories={objectView.history}
              isProjectArchived={isProjectArchived}
              objectId={objectId}
              organizations={projectOrganizations}
              toggleDrawer={() => toggleDrawer(DRAWER_RIGHT_OPEN)}
            />
          )
        ) : null}
      </Drawer>
    </>
  );
};

ObjectList.propTypes = {
  history: PropTypes.object
};

export default withRouter(ObjectList);
