import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ACTION_GET, FILE_PATH, MS_VIEWER_URL_FORMAT } from 'common/constants';
import { getMsFileForViewer, getPresignedUrl } from 'common/ursa';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useStyles } from '../styles';
import { useIntl } from 'react-intl';

const PreviewPane = ({
  bucket,
  children,
  encodeUrl,
  filePath,
  srcFormat,
  style
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [error, setError] = useState('');
  const [mySignedUrl, setMySignedUrl] = useState({
    signedUrl: '',
    path: ''
  });

  const isMSFile = useMemo(() => srcFormat.includes(MS_VIEWER_URL_FORMAT), [
    srcFormat
  ]);

  useEffect(() => {
    let isMounted = true;
    const { signedUrl, path } = mySignedUrl;
    if (!signedUrl || filePath !== path) {
      const url = new URL(filePath);

      if (isMSFile) {
        getMsFileForViewer(bucket, url.pathname).then(data => {
          if (!data) {
            setError(intl.formatMessage({ id: 'error.CANNOT_LOAD_FILE' }));
            return;
          }

          if (isMounted && data.blob) {
            setMySignedUrl({
              signedUrl: URL.createObjectURL(data.blob),
              path: filePath
            });
          }
        });
      } else {
        getPresignedUrl(ACTION_GET, url.pathname, bucket).then(data => {
          if (isMounted) {
            const srcUrl = encodeUrl
              ? encodeURIComponent(data.signedUrl)
              : data.signedUrl;
            setMySignedUrl({
              signedUrl: srcFormat.replace(FILE_PATH, srcUrl),
              path: filePath
            });
          }
        });
      }
    }
    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucket, filePath, mySignedUrl]);

  useEffect(() => {
    // Clean up the object URL when component unmounts
    return () => {
      if (isMSFile && mySignedUrl.signedUrl) {
        URL.revokeObjectURL(mySignedUrl.signedUrl);
      }
    };
  }, [isMSFile, mySignedUrl.signedUrl]);

  return (
    <div className={classes.previewPlaceholder} style={style}>
      {React.cloneElement(children, { src: mySignedUrl.signedUrl, error })}
    </div>
  );
};

PreviewPane.propTypes = {
  bucket: PropTypes.string,
  children: PropTypes.object.isRequired,
  encodeUrl: PropTypes.bool,
  filePath: PropTypes.string.isRequired,
  srcFormat: PropTypes.string.isRequired,
  style: PropTypes.object
};

export default PreviewPane;
