import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInterval } from 'hooks';
import { CircularProgress } from '@material-ui/core';

const Iframe = props => {
  const [srcForm, setSrcFile] = useState({
    srcFile: '',
    isTimerRunning: !!props.error
  });
  useEffect(() => {
    if (props.src && srcForm.srcFile !== props.src && !props.error) {
      setSrcFile({ srcFile: props.src, isTimerRunning: true });
    }
  }, [props.error, props.src, srcForm]);

  const resetSrc = () => setSrcFile(s => ({ ...s, srcFile: props.src }));
  const stopInterval = useInterval(
    resetSrc,
    3000,
    !process.env.REACT_APP_SUPPORTED_MS_FILES.includes(props.filetype)
  );

  const stop = () => {
    setSrcFile(s => ({ ...s, isTimerRunning: false }));
    stopInterval();
  };
  return srcForm.srcFile === props.src ? (
    <>
      <iframe
        {...props}
        height={props.height ? props.height : '600'}
        onLoad={stop}
        src={srcForm.srcFile}
        title={props.title}
      />
      <div
        style={{
          display: srcForm.isTimerRunning ? 'block' : 'none'
        }}>
        <CircularProgress
          style={{
            position: 'absolute',
            top: '40%',
            left: '47%'
          }}
        />
      </div>
      {props.error ? (
        <div style={{ position: 'absolute' }}>{props.error}</div>
      ) : null}
    </>
  ) : null;
};
Iframe.propTypes = {
  error: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string.isRequired
};
export default Iframe;
