import axios from 'axios';
import {
  ACTION_DELETE,
  ACTION_GET,
  ACTION_PUT,
  AWS_URL,
  BUCKET_ATTACHMENTS
} from './constants';
import { convertToPdf } from './gotenberg';

const uploaderUrl = process.env.REACT_APP_URSA_URL
  ? process.env.REACT_APP_URSA_URL
  : 'http://localhost:8090';
const envPath =
  (process.env.REACT_APP_CUSTOM_NODE_ENV ||
    process.env.NODE_ENV ||
    'development') + '/';
const endpoint = '/sign/';
const deleteEndpoint = '/delete/';
const moveEndpoint = '/move/';
const awsUrl = AWS_URL;

export const getPresignedUrl = async (
  method,
  filename,
  bucket = BUCKET_ATTACHMENTS,
  isNew
) => {
  const url = (uploaderUrl + endpoint)
    .concat(isNew ? envPath : '')
    .concat(filename.replace(/^\/+/, '')) // remove leading slash
    .concat(`?bucket=${bucket}`);

  try {
    const resp = await axios({ method: method, url: url });
    return { signedUrl: resp.data.SignedUrl, fileUrl: resp.data.FileUrl };
  } catch (err) {
    return { error: err };
  }
};

const fetchS3Blob = signedUrl => {
  return axios
    .get(signedUrl, { responseType: 'blob' })
    .then(response => response.data);
};

export const fetchFileBlobFromS3 = async (bucket, filename) => {
  if (!filename) return;

  const { signedUrl } = await getPresignedUrl(ACTION_GET, filename, bucket);
  const blob = await fetchS3Blob(signedUrl);
  return { blob, signedUrl };
};

export const getMsFileForViewer = async (bucket, filename) => {
  const fileBlob = await fetchFileBlobFromS3(bucket, filename);
  if (!fileBlob) return;

  const { blob, signedUrl } = fileBlob;
  const pdfBlob = await convertToPdf(filename, blob);

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const buffer = reader.result;
      const fileBlob = new Blob([new Uint8Array(buffer)], {
        type: pdfBlob.type
      });
      resolve({ blob: fileBlob, signedUrl });
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsArrayBuffer(pdfBlob);
  });
};

export const actionOnS3File = (
  method,
  file,
  new_file,
  bucket = BUCKET_ATTACHMENTS
) => {
  if (!file) return;

  let endpoint = '';
  let data = {};
  const url =
    bucket !== BUCKET_ATTACHMENTS
      ? awsUrl.replace(BUCKET_ATTACHMENTS, bucket)
      : awsUrl;
  switch (method) {
    case ACTION_DELETE:
      endpoint = deleteEndpoint;
      break;
    case ACTION_PUT:
      if (!new_file || file === new_file) return;

      endpoint = moveEndpoint;
      data = {
        new_name: new_file.startsWith(url) ? new_file.split(url)[1] : new_file
      };
      break;
    default:
      return;
  }

  file = file.startsWith(url) ? file.split(url)[1] : file;

  return axios({
    url: `${uploaderUrl}${endpoint}${file.replace(
      /^\/+/,
      ''
    )}?bucket=${bucket}`,
    method: method,
    data: data
  });
};
