import axios from 'axios';
import { VEGA_URL } from './constants';

const vega = axios.create({
  baseURL: process.env.REACT_APP_VEGA_URL ?? VEGA_URL
});

export const convertToPdf = (filename, blob) => {
  const formData = new FormData();
  formData.append(filename, blob, filename);

  return vega
    .post('/gotenberg/forms/libreoffice/convert', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'blob'
    })
    .then(response => response.data);
};
